// Module colors

$wisdom : #FFCE00 !default;
$action : #F94141 !default;
$elevate : #7642AD !default;
$navigate : #39A2DB !default;
$journey : #F7931E !default;
$eureka : #C400B6 !default;
$planet : #2FB06D !default;
$primary : #2848af !default;


.text-wisdom {
    color: $wisdom !important;
}

.bg-wisdom {
    background-color: $wisdom !important;
}

.text-action {
    color: $action !important;
}

.bg-action {
    background-color: $action !important;
}

.text-elevate {
    color: $elevate !important;
}

.bg-elevate {
    background-color: $elevate !important;
}

.text-navigate {
    color: $navigate !important;
}

.bg-navigate {
    background-color: $navigate !important;
}

.text-journey {
    color: $journey !important;
}

.bg-journey {
    background-color: $journey !important;
}

.text-eureka {
    color: $eureka !important;
}

.bg-eureka {
    background-color: $eureka !important;
}

.text-planet {
    color: $planet !important;
}

.bg-planet {
    background-color: $planet !important;
}

.myprism {
    .features__item {
        min-height: 220px;
    }

    .features__item-2 {
        padding: 25px 20px;

        p {
            margin-bottom: 0;
            min-height: 60px;
        }
        img {
            height: 48px;
        }
    }

}

.myprism {
    &:nth-child(1) {
        .features__item {
            background-image: linear-gradient(60deg, $wisdom 0%, darken($wisdom, 10%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $wisdom 0%, darken($wisdom, 10%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(2) {
        .features__item {
            background-image: linear-gradient(60deg, $action 0%, darken($action, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $action 0%, darken($action, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(3) {
        .features__item {
            background-image: linear-gradient(60deg, $elevate 0%, darken($elevate, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $elevate 0%, darken($elevate, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(4) {
        .features__item {
            background-image: linear-gradient(60deg, $navigate 0%, darken($navigate, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $navigate 0%, darken($navigate, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(5) {
        .features__item {
            background-image: linear-gradient(60deg, $journey 0%, darken($journey, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $journey 0%, darken($journey, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(6) {
        .features__item {
            background-image: linear-gradient(60deg, $eureka 0%, darken($eureka, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $eureka 0%, darken($eureka, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
    &:nth-child(7) {
        .features__item {
            background-image: linear-gradient(60deg, $planet 0%, darken($planet, 20%) 100%) !important;
            & h3, .link-btn  {
                color: $white;
            }
            .features__thumb-2::after {
                background-image: linear-gradient(60deg, $planet 0%, darken($planet, 20%) 100%) !important;
            }
        }
        .features__icon-2 i {
            color: $white;
        }
    }
}

// .myprism {
//    &:nth-child(1) {
//         .features__icon-2 i{
//             color: $wisdom;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $wisdom 0%, darken($wisdom, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(2) {
//         .features__icon-2 i{
//             color: $action;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $action 0%, darken($action, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(3) {
//         .features__icon-2 i{
//             color: $elevate;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $elevate 0%, darken($elevate, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(4) {
//         .features__icon-2 i{
//             color: $navigate;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $navigate 0%, darken($navigate, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(5) {
//         .features__icon-2 i{
//             color: $journey;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $journey 0%, darken($journey, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(6) {
//         .features__icon-2 i{
//             color: $eureka;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $eureka 0%, darken($eureka, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
//     &:nth-child(7) {
//         .features__icon-2 i{
//             color: $planet;
//         }
//         .features__thumb-2::after{
//             background-image: linear-gradient(60deg, $planet 0%, darken($planet, 20%) 100%) !important;
//             opacity: 0.8;
//         }
//         &:hover i{
//             color: #fff;
//         }
//     }
// }

.myprism-logo {
    & img {
        height: 185px;
    }
}

a.card.cursor-pointer {
    h4 {
        color: $primary;
    }
    &:hover {
        img {
            border: 3px solid $primary;
        }
       
    }

}
.download-casestudy {
    display: inline-flex;
    margin-bottom: 45px;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    &:hover {
        border-color: $primary;

        p{
            color: $primary;
        }
    }
}

